import React from 'react'
import "../assets/css/subhead.scss"


const ColoredLine = ({ gridColumn }) => (
    <hr
        style={{
            color: '#DCBB41',
            backgroundColor: '#DCBB41',
            height: 5,
            width: '100%',
            border: '#DCBB41',
            alignItems: 'center',
            gridColumn: gridColumn
        }}
    />
);


export const SubHead = (props) => {
    return (
            <div className={`row container ${props.text}`}>
                <ColoredLine gridColumn={'2 /3'}/>
                <div style={{gridColumn:"3 / 12"}} class="separator">{props.text}</div>

                {/* <div style={{gridColumn:"3 / 12", display: 'flex', alignItems: 'center,', textAlign: 'center'}}> */}
                    {/* <h2 style={{textAlign:"center", display: "inline"}}></h2>
                    <hr
                        style={{
                            color: '#DCBB41',
                            backgroundColor: '#DCBB41',
                            height: 5,
                            width: '100%',
                            border: '#DCBB41',
                            alignItems: 'center',
                            // gridColumn: gridColumn
                        }}
                    /> */}


            </div>
    )
}





