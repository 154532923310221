import React from "react"
import { Background } from "../components/Background"
import { Helmet } from "react-helmet"
import { Nav } from "../components/Nav"
import { Accordion } from "../components/Accordion"
import { Gallery } from "../components/Gallery"
import { graphql, useStaticQuery, StaticQuery } from "gatsby"
import { SubHead } from "../components/SubHead"
import "../assets/css/main.scss"

const { largeParagraph, smallParagraphs } = require("../../cms/bio/bio.json")

const convertText = str => {
  return str.replace("\n", "<br /><br />")
}

export default function Home() {
  return (
    <div>
        <Helmet>
          {/* <meta charSet="utf-8" /> */}
          <title>Astrid Kunst</title>
          <meta name="description" content="Artist website for Astrid Kunst" />
          <meta name="robots" content="index, follow" />
        </Helmet>

      <Nav />

      <div className={"videoContainer"}>
        <Background />
      </div>

      <div
        className={"sectionBg"}
        style={{
          backgroundImage: `url(${require("../../static/video/astridBg.jpg")}`,
        }}
      >
        <div id={"bio"}>
          <SubHead text="BIO" />
          <div className={"bg-wrapper"}>
            <section className="container">
              <div className="row">
                <p
                  className={"bioParagraph largeBioParagraph"}
                  dangerouslySetInnerHTML={{
                    __html: convertText(largeParagraph),
                  }}
                ></p>
                <p
                  className={"bioParagraph smallBioParagraph"}
                  dangerouslySetInnerHTML={{
                    __html: convertText(smallParagraphs),
                  }}
                ></p>
              </div>
            </section>
          </div>
        </div>

        <div id={"book"}>
          <SubHead text="INFO" />
          <div className={"bg-wrapper"}>
            <section className="container">
              <div className={"row"}>
                <StaticQuery
                  query={graphql`
                    query {
                      allMarkdownRemark(
                        sort: { order: ASC, fields: [frontmatter___order] }
                      ) {
                        edges {
                          node {
                            id
                            html
                            frontmatter {
                              order
                              title
                            }
                          }
                        }
                      }
                    }
                  `}
                  render={data => {
                    return data.allMarkdownRemark.edges.map(edge => {
                      return (
                        <Accordion
                          title={edge.node.frontmatter.title}
                          content={edge.node.html}
                        />
                      )
                    })
                  }}
                />
                <p className={"packageConditions"}>
                  * Current available DJ's are based in France, the Netherlands
                  and Brasil
                </p>
                <p className={"packageConditions"}>
                  * Current available guitarists and saxophonists are based in
                  France and in the Netherlands
                </p>
                <span id={"packageDownload"}>
                  <a href={require("../../static/assets/show_packages.pdf")}>
                    DOWNLOAD FULL PACKAGE LIST
                  </a>
                </span>
              </div>
            </section>
          </div>
        </div>

        <div id={"media"}>
          <SubHead text="MEDIA" />
          <div className={"bg-wrapper"}>
            <section className="container">
              <Gallery />
            </section>
          </div>
        </div>

        <div id={"contact"}>
          <SubHead text="BOOKINGS " />
          <div className={"bg-wrapper"}>
            <section className="container">
              <p className={"infoHead"}>
                BOOKINGS & INFO |{" "}
                <a
                  href={"mailto:info@astridkunst.com"}
                  target={"__blank"}
                  id={"emailLink"}
                >
                  info@astridkunst.com
                </a>
              </p>
              <p className={"infoHead"}>
                <a href={"assets/press_kit_2021.zip"} target={"_blank"}>
                  PRESS KIT
                </a>
              </p>
            </section>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
      </div>
    </div>
  )
}
