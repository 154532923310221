import React, { useState } from "react"
import "../assets/css/gallery.scss"

import FsLightbox from "fslightbox-react"

function importAll(r) {
  return r.keys().map(r)
}

function applyYoutube(arr){
    const youtubeLinks = {
        2 : 'https://www.youtube.com/watch?v=79GMkbzRxbI',
        7 : 'https://www.youtube.com/watch?v=KL_ZKwvZW2U',
        12 : 'https://www.youtube.com/watch?v=EBjeEYMHeFA'
    }
    const test = arr.map((image, i) => {
        return (typeof youtubeLinks[i] === 'string') ? youtubeLinks[i] : image
    })
    return test
}

const imageNumber = str => {
  const number = str.charAt(8)
  return str.charAt(9) !== "-" ? number + str.charAt(9) : number
}

const images = importAll(
  require.context("../../static/assets", false, /\.(png|jp?g)$/)
).sort((a, b) => imageNumber(a) - imageNumber(b))

export const Gallery = () => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    })
  }

  return (
    <div className={"image-gallery"}>
      {images.map((image, i) => {
        return (
          <a
            className={"img-" + imageNumber(image)}
            style={{
              backgroundImage: `url(${image})`,
              gridArea: `img-${imageNumber(image)}`,
            }}
            onClick={() => {
              openLightboxOnSlide(i+1)
            }}
          ></a>
        )
      })}
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={applyYoutube(images)}
        slide={lightboxController.slide}
      />
    </div>
  )
}
