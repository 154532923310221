import React, {useState, useRef} from "react"
import "../assets/css/packages.scss"

function Chevron(props) {
    return (
      <svg
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 320 512"
      >
        <path
          fill={props.fill}
          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
        />
      </svg>
    );
  }

export const Accordion = (props) => {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setAccordionCSS, setAccordionCSSState] = useState({backgroundColor: 'rgb(166, 153, 86)', borderRadius: '5px 5px 5px 5px'});
    const [setRotate, setRotateState] = useState("accordion__icon");
  
    const content = useRef(null);
  
    function toggleAccordion() {
      setActiveState(setActive === "" ? "active" : "");
      setHeightState(
        setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
      );
      setAccordionCSSState(
        setActive === "active" ? {backgroundColor: 'rgb(166, 153, 86)', borderRadius: '5px 5px 5px 5px'} : {backgroundColor: 'rgba(194, 191, 167)', borderRadius: '0px 0px 5px 5px'}
      );
      setRotateState(
        setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
      );
    }
  
    return (
        <div className="accordion__section">
          <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
          <p className="accordion__title">{props.title}</p>
          <Chevron className={`${setRotate}`} fill={"#777"} />
          </button>
          <div
          ref={content}
          style={{ maxHeight: `${setHeight}`, backgroundColor: `${setAccordionCSS.backgroundColor}`, borderRadius: `${setAccordionCSS.borderRadius}`}}
          className="accordion__content"
          >
          <div
              className="accordion__text"
              dangerouslySetInnerHTML={{ __html: props.content }}
          />
          </div>
        </div>


    );
}
