import React from "react";

const homepageLinks = [
    {label: 'BIO', linkAddress: "#bio"},
    {label: 'INFO', linkAddress: "#book"},
    {label: 'MEDIA', linkAddress: "#media"},
    {label: 'BOOKINGS', linkAddress: "#contact"},
    {label: 'PRESS KIT', linkAddress: "#contact"},
]

const socialLinks = [
    {name: 'soundcloud', icon: require("../assets/logo/soundcloud.svg"), linkAddress: "https://soundcloud.com/astrid-kunst-official"},
    {name: 'facebook', icon: require("../assets/logo/facebook.svg"), linkAddress: "https://www.facebook.com/AstridKunstOfficial"},
    {name: 'instagram', icon: require("../assets/logo/instagram.svg"), linkAddress: "https://www.instagram.com/astridkunst/?hl=en"},
    {name: 'youtube', icon: require("../assets/logo/youtube.svg"), linkAddress: "https://www.youtube.com/user/gedoe2011"}
]

const iconStyle = {
    width: '27.43px',
    height: "100%",
    color: '#FFFFFF'
}


export function Nav() {
    return (
        <nav>
            <img src={require("../assets/logo/Logo_KLein_Wit.png")} alt="Astrid Kunst" label="Astrid Kunst" />
            <div className={'homepageLinkGroup'}>
                {homepageLinks.map(link => {
                    return <a href={link.linkAddress} className="homepageLink">{link.label}</a>
                })}
            </div>

            <div className={'socialLinks'}>
                {socialLinks.map(link => {
                        return <a href={link.linkAddress} target={'_black'}><img style={iconStyle} src={link.icon} /></a>
                })}
            </div>
        </nav>
    )
}