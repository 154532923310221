import '../../static/video/plyr.css';
import React, { useEffect, useState } from 'react';

const options = {
  controls: ['mute', 'volume', 'play-large', 'play'],
  volume: 0,
  muted: true,
  autoplay: true,
  clickToPlay: false,
  hideControls: false,
  fullscreen: {enabled: false},
  // loop: true
  }


export const Background = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map(p => {
        const video = new Plyr(p, {...options})
        video.loop = true
        video.poster = require('../../static/video/video-plyr-cover.jpg')
        console.log(video.poster)
      });

    }
  }, []);


  

  return (
      <div className="video-cover">
        <div
        className="js-player"
        data-plyr-provider="youtube"
        data-plyr-embed-id={"KL_ZKwvZW2U"}
        data-poster={require('../../static/video/video-plyr-cover.jpg')}
        />
      </div>

  );
};

